import axios from "axios";
import validator from 'validator';
import { api } from "./apiService";
import { getJWT, jwtRes } from "./jwtService";
import { UserData } from "./userService";

export const customerOptions: {id: string, title: string}[] = [
  {id: "customer", title: "Kunde"},
  {id: "address", title: "Adresse"},
  {id: "zip", title: "PLZ"},
  {id: "cityAndCountry", title: "Stadt, Land"},
  {id: "contactPerson", title: "Kontaktperson"},
  {id: "email", title: "Email"},
  {id: "phone", title: "Telefon"},
]

export interface ExitOrderData {
  customer: string;
  address: string;
  zip: string;
  cityAndCountry: string;
  contactPerson: string;
  email: string;
  phone: string;
  reference: string;
  category: string;
  positions: ExitPos[];
  isDraft: boolean;
}

export interface ExitOrderAttributes extends ExitOrderData {
  isDraft: boolean;
  isFulfilled: boolean;
  readOnly: boolean;
  customId: string;
  createdAt: string;
  documents: {data: {
    attributes: {
      name: string;
      url: string;
    }
  }[]};
  deliveryCost:number;
  creator: {data: {attributes: UserData}}
  isCancelled: boolean;
}

export interface ExitOrder {
  id: number;
  attributes: ExitOrderAttributes;
}

export interface ExitPos {
  name: string;
  quantity: number;
  unit: string;
  price: number;
  priceUnit: string;
  total: number;
  trucks: ExitTruckData[];
  color: string;
  foodSafe: string;
  material: string;
  packaging: string;
}

export interface ExitTruckData {
  plate: string;
  dispatchDate: Date | null;
  startTime:string;
  endTime:string;
  storagePlace: number;
  goods: string[];
  storageTotal: number;
  fulfilled: boolean;
  fulfillData: {
    id: string;
    amount: number;
  }[];
}

export interface saveOrderRes {
  success: true;
  message: string;
}

export interface fetchExitOrdersRes {
  success: true;
  data: ExitOrder[];
  message: string;
}

export interface PrepareDataBody {
  positions: ExitPos[];
  id: number;
}

export interface SubmitExitTruckData {
  orderId: number;
  posId: number;
  deliveryCost: number,
  truckId: number;
  onTruckData: OnTruckData[];
}

export interface OnTruckData {
  id: string;
  amount: number;
}

export interface submitExitTruckRes {
  success: boolean;
  message: string;
}

export let fetchExitOrders = (filters: string = '',sort:string=''): Promise<fetchExitOrdersRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        data: [],
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.get(api + `/api/exit-orders?sort[0]=${sort ? sort :'createdAt%3Adesc'}${filters ? '&' + filters : ''}&populate=*`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data,
          message: 'success'
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: [],
          message: ''
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let saveOrder = (data: ExitOrderData, isUpdate: boolean = false, orderId: number = -1): Promise<saveOrderRes> => {
  return new Promise((resolve, reject) => {
    let error = '';

    let {customer, address, zip, cityAndCountry, reference, contactPerson, email, phone, positions, isDraft} = data;

    if (!isDraft) {
      if (!customer) error = 'Bitte gib den Lieferanten an.'
      else if (!address) error = 'Bitte gib die Adresse des Lieferanten an.'
      else if (!zip) error = 'Bitte gib die PLZ des Lieferanten an.'
      else if (!cityAndCountry) error = 'Bitte gib die Stadt und das Land des Lieferanten an.'
      else if (!contactPerson) error = 'Bitte gib die Kontaktperson des Lieferanten an.'
      else if (!email || !validator.isEmail(email)) error = 'Bitte gib eine gültige Email des Lieferanten an.'
      else if (!phone) error = 'Bitte gib die Telefonnummer des Lieferanten an.'
      else if (!reference) error = 'Bitte gib die Referenz an.'
      else if (!positions || positions.length < 1) error = 'Bitte gib Positionen an.'
      else if (positions.length >= 1) positions.forEach(pos => {
        if (!pos.name || !pos.quantity || !pos.unit || !pos.price || !pos.priceUnit || !pos.color || !pos.foodSafe || !pos.material) error = 'Bitte fülle die Daten aller Positionen vollständig aus.'
      })
    }

    if (!error && isUpdate && orderId < 0) error = 'Fehler beim Aktualisieren des Auftrags.';

    if (error) return reject({
      success: false,
      message: error
    })

    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      axios[isUpdate ? 'put' : 'post'](api + '/api/exit-orders/' + (isUpdate && orderId ? orderId : ''), {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: 'success'
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = `Fehler beim ${isUpdate ? 'Aktualisieren' : 'Erstellen'} des Auftrags.`
        }

        reject({
          success: false,
          message: message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let saveUpdateDateOrder = (data:any,orderId: number) => {

  return new Promise((resolve, reject) => {
  
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      axios['put'](api + '/api/exit-orders/' +orderId , {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: 'success'
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = `Fehler beim 'Aktualisieren' des Auftrags.`
        }

        reject({
          success: false,
          message: message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let sendPrepareData = (data: PrepareDataBody): Promise<saveOrderRes> => {
  return new Promise((resolve, reject) => {
    let error = '';

    let {id} = data;

    if (!(id || id === 0)) error = 'Auftrag nicht im System gefunden'

    if (error) return reject({
      success: false,
      message: error
    })

    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      axios.put(api + '/api/exit-orders/prepare-data/', {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: 'success'
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = `Fehler beim Aktualisieren des Auftrags.`
        }

        reject({
          success: false,
          message: message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let submitExitTruck = (data: SubmitExitTruckData): Promise<submitExitTruckRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.post(api + '/api/exit-orders/submit-truck/', {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: 'success'
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = `Fehler beim Aktualisieren des Auftrags.`
        }

        reject({
          success: false,
          message: message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let cancelExitOrder = (id: number): Promise<{success: boolean, message: string}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      let data = {
        id
      };
      
      axios.post(api + '/api/exit-orders/cancel', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: '',
        })
      })
      .catch(err => {
        reject({
          success: false, 
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Aktualisieren der Bestellung.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let fetchExitOrdersWithFilters = (filters: {[key: string]: string[]}, sortBy: {[key: string]: string} = {}): Promise<fetchExitOrdersRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.post(api + `/api/exit-orders/custom-filter?populate=*`, {data: {filters, sortBy}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data || [],
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: [],
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}