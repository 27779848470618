import { Add, Close, ExpandMoreOutlined, QrCode2 } from '@mui/icons-material';
import { Modal, Box, TextField, Accordion, AccordionDetails, AccordionSummary, InputAdornment, MenuItem, Button, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import React, { FC, useState } from 'react';
import { formatter } from '../../../../services/calculator';
import { toLocalISODate, convertGoodsCategory, localNumber, convertOrderAttributes, localPriceInTons, translate, localCurrency, convertDateString } from '../../../../services/stringConverter';
import { customerOptions, ExitPos } from '../../../../services/warehouseExitService';
import { NotiStore } from '../../../../stores/notifications';
import { foodSafeOptions, materials, rpetColors } from '../../../../utils/defaults';
import { WarehouseExitState } from '../WarehouseExit';
import './WarehouseExitForm.sass';
import { Link } from 'react-router-dom';
const pdfjsLib: any = require("pdfjs-dist/build/pdf");
// pdfjsWorker is needed for pdfjsLib
// eslint-disable-next-line @typescript-eslint/no-unused-vars 
const pdfjsWorker: any = require("pdfjs-dist/build/pdf.worker.entry");

interface WarehouseExitFormProps {
  handleSpotChange:any;
  checkExitDate:any;
  showProductDetail: any;
  onCloseDetailPop:any;
  handleUpdate:any;
  active: boolean;
  onClose: () => void;
  addNewPos: () => void;
  handlePosChange: (e: React.ChangeEvent<HTMLInputElement> | {target: {name: string, value: string | number}}) => void;
  handlePosAccordion: (id: number) => void;
  handleDeletePos: (id: number) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | {target: {name: string, value: string}}) => void;
  addTruckToPos: (i: number) => void;
  deleteTruck: (i: number, j: number) => void;
  handleTruckChange: (e: React.ChangeEvent<HTMLInputElement>, i: number, j: number) => void;
  handleGood: (i: number, j: number, id: string) => void;
  calcTruckTotal: (i: number, j: number) => number;
  calcPosTotal: (i: number) => number;
  sendPrepareData: () => void;
  submitForm: (isDraft: boolean, isUpdate: boolean) => void;
  cancelExitOrder: (id: number) => void;
  popMode: string;
  state: WarehouseExitState;
  rights: string[];
}

const unitOptions = [
  {
    value: 'kg',
    label: 'kg'
  },
  {
    value: 't',
    label: 't'
  },
  {
    value: 'stk',
    label: 'Stk.'
  },
  {
    value: 'l',
    label: 'l'
  },
]

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

const WarehouseExitForm: FC<WarehouseExitFormProps> = (props) => {
  let [show, setShow] = useState(false);
  let [showPos, setShowPos] = useState(-1);
  let [showTruck, setShowTruck] = useState(-1);
  let [storage, setStorage] = useState('');

  let showPop = (pos: number, truck: number): void => {
    if (props.state.positions[pos].trucks[truck].storagePlace >= 0 || props.state.positions[pos].trucks[truck].storagePlace === 0) {
      setShow(true)
      setShowPos(pos)
      setShowTruck(truck)
      setStorage(props.state.positions[pos].trucks[truck].storagePlace.toString())
    } else {
      NotiStore.setState({error: true, errorMessage: 'Bitte wähle zuerst ein Lager.'});
    }
  }

  let getGoodData = (customId: string): {type: string, quantity: number, storage: string,id:number} => {
    let {goods} = props.state;

    let data = {
      id:0,
      type: '???',
      quantity: 0,
      storage: '???'
    }

    let match = goods.filter(g => g.attributes.customId.toLowerCase() === customId.toLowerCase());
    if (match.length === 1) {
      data.type = match[0].attributes.typeIntern ? match[0].attributes.typeIntern : '???';
      data.storage = match[0].attributes.warehouse_spot.data && match[0].attributes.warehouse_spot.data.attributes.name ? match[0].attributes.warehouse_spot.data.attributes.name : '???';
      data.quantity = match[0].attributes.quantity ? match[0].attributes.quantity : 0;
      data.id = match[0].id ? match[0].id : 0
    }

    return data
  }

  let onFileUpload = (e: any): void => {
    if (e.target.files && typeof e.target.files[0] !== 'undefined') {
      let file = e.target.files[0];

      var url = URL.createObjectURL(file)

      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then(function(pdf: any) {
        
        // Fetch the first page
        var pageNumber = 1;
        pdf.getPage(pageNumber).then(function(page: any) {
          
          page.getTextContent()
          .then((content: {items: {str: string}[]}) => {
            let {items} = content;

            if (items && items.length >= 1) {
              if (items[58] && items[58].str) props.handleChange({target: {name: 'customer', value: items[58].str}})
              if (items[59] && items[59].str) props.handleChange({target: {name: 'address', value: items[59].str}})
              if (items[60] && items[60].str) props.handleChange({target: {name: 'zip', value: items[60].str.split(' ')[0]}})
              if (items[60] && items[60].str && items[61] && items[61].str) props.handleChange({target: {name: 'cityAndCountry', value: `${items[60].str.split(/ (.*)/s)[1]}, ${items[61].str}`}})
              if (items[65] && items[65].str) props.handleChange({target: {name: 'reference', value: items[65].str}})

              if (items[35] && items[35].str) props.handlePosChange({target: {name: 'name-0', value: items[35].str}})
              if (items[37] && items[37].str) props.handlePosChange({target: {name: 'quantity-0', value: Number(items[37].str.replace('.', '').replace(',', '.').split(' ')[0])}})
              if (items[39] && items[39].str) props.handlePosChange({target: {name: 'price-0', value: Number(items[39].str.replace('.', '').replace(',', '.').split(' ')[0])}})
              if (items[37] && items[37].str) {
                let value = items[37].str.split(' ')[1]
                if (value === 'kg' || value === 't' || value === 'l' || value === 'stk') {
                  props.handlePosChange({target: {name: 'unit-0', value}})
                  props.handlePosChange({target: {name: 'priceUnit-0', value}})
                }
              }
            }
          })
          .catch(() => {
            NotiStore.setState({error: true, errorMessage: 'Fehler beim Lesen des Dokuments.'})
          })
        });
      }, function (err: any) {
        // PDF loading error
        console.error(err);
        NotiStore.setState({error: true, errorMessage: 'Fehler beim Lesen des Dokuments.'})
      });
    }
  }

  let calcTruckFulfilledAmount = (pos: ExitPos): number => {
    let result = 0

    if (pos.trucks && pos.trucks.length >= 1) pos.trucks.forEach(truck => {
      if (truck.fulfillData && truck.fulfillData.length >= 1) truck.fulfillData.forEach(data => {
        if (data.amount) result += data.amount
      })
    })

    return result
  }


  return (
   <>
    <Modal
      open={props.active}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="WarehouseExitForm"
    >
      <>
        <span className='modal-close pointer' onClick={props.onClose}><Close /></span>
        <Box sx={modalStyle}>
          <div className='modal-close-btn' onClick={props.onClose}><Close /></div>
          <div className="header-bar">
            <h2>Auftrag {props.popMode === 'create' && 'erstellen'}{props.popMode === 'edit' && 'bearbeiten'}{props.popMode === 'view' && (props.state.orders.filter(o => o.id === props.state.activeOrderId)[0].attributes.customId || '???')}</h2>
            {props.popMode === 'create' && (
              <div>
                <Button variant='contained' onClick={() => {if (document.getElementById('file')) document.getElementById('file')?.click()}}>PDF einlesen</Button>
                <input type="file" id='file' onChange={onFileUpload} style={{height: 0, width: 0, overflow: 'hidden', zIndex: -999, opacity: 0}} />
              </div>
            )}
          </div>
          <h3>Kunde</h3>
          <div className='flex form-fields'>
            {customerOptions.map((item, i) => (
              <React.Fragment key={item.id}>
                {i === 4 && <div className='form-field'></div>}
                <div className={'form-field ' + item.id} key={"form-field-" + i}>
                  <div className="input">
                  <TextField
                    name={`${item.id}`}
                    type={item.id === 'email' ? 'email' : 'text'}
                    helperText={item.title}
                    //@ts-ignore
                    value={props.state[item.id] || ''}
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                    // error={props.state[item.id] || '' ? false : true }
                    disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                  />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <hr />
          <h3>Auftrag</h3>
          <div className="flex of-2 spacing-1">
            <TextField
              name={`reference`}
              type='text'
              error={props.state.reference ? false : true }
              helperText={'Referenz'}
              //@ts-ignore
              value={props.state.reference}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
              disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
            />
            <TextField
              name={`category`}
              type='text'
              helperText={'Kategorie'}
              //@ts-ignore
              error={props.state.category ? false : true }
              value={props.state.category}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
              disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
              select
            >
              <MenuItem value='product'>Produkt</MenuItem>
              <MenuItem value='trading'>Handel</MenuItem>
              <MenuItem value='nm'>Nebenstrom</MenuItem>
              <MenuItem value='Verarbeitung'>Verarbeitung</MenuItem>
              <MenuItem value='Zwischenlager'>Zwischenlager</MenuItem>
            </TextField>  
          </div>
          <hr />
          <h3>Positionen</h3>
          <div className='positions'>
            {props.state.positions.map((pos, i) => (
              <div className="position" key={'pos'+i}>
                <Accordion key={'pos-'+i} expanded={props.state.expandedPos === i + 1}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined onClick={() => props.handlePosAccordion(i + 1)} className='pointer' />}
                    style={{cursor: 'default'}}
                  >
                    <strong>Position {i + 1}</strong>
                    {props.state.positions.length > 1 && <u style={{marginLeft: 20, cursor: 'pointer'}} onClick={() => props.handleDeletePos(i)}>löschen</u>}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className="flex of-5 spacing-1 form">
                        <div>
                          <TextField
                            name={`name-${i}`}
                            type='text'
                            helperText={'Bezeichnung'}
                            //@ts-ignore
                            error={props.state.positions[i].name ? false : true }
                            value={props.state.positions[i].name}
                            fullWidth
                            onChange={props.handlePosChange}
                            size='small'
                            disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                          />
                        </div>
                        <div>
                          <TextField
                            name={`color-${i}`}
                            select
                            helperText={'Farbe'}
                            //@ts-ignore
                            error={props.state.positions[i].color ? false : true }
                            value={props.state.positions[i].color}
                            fullWidth
                            onChange={props.handlePosChange}
                            size='small'
                            disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                          >
                            {rpetColors.map(color => <MenuItem value={color} selected={props.state.positions[i].color === color}>{convertOrderAttributes(color)}</MenuItem>)}
                          </TextField>
                        </div>
                        <div>
                          <TextField
                            name={`foodSafe-${i}`}
                            select
                            helperText={'Lebensmittelecht'}
                            //@ts-ignore
                            error={props.state.positions[i].foodSafe ? false : true }
                            value={props.state.positions[i].foodSafe}
                            fullWidth
                            onChange={props.handlePosChange}
                            size='small'
                            disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                          >
                            <MenuItem value='yes' selected={props.state.positions[i].foodSafe === 'yes'}>Ja</MenuItem>
                            <MenuItem value='no' selected={props.state.positions[i].foodSafe === 'no'}>Nein</MenuItem>
                          </TextField>
                        </div>
                        <div>
                          <TextField
                            name={`material-${i}`}
                            select
                            helperText={'Material'}
                            //@ts-ignore
                            error={props.state.positions[i].material ? false : true }
                            value={props.state.positions[i].material}
                            fullWidth
                            onChange={props.handlePosChange}
                            size='small'
                            disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                          >
                            <MenuItem value='tray' selected={props.state.positions[i].material === 'tray'}>Tray</MenuItem>
                            <MenuItem value='bottle' selected={props.state.positions[i].material === 'bottle'}>Bottle</MenuItem>
                            <MenuItem value='bottleAndTray' selected={props.state.positions[i].material === 'bottleAndTray'}>Bottle + Tray</MenuItem>
                          </TextField>
                        </div>
                        <div>
                          <TextField
                            name={`packaging-${i}`}
                            select
                            helperText={'Verpackung'}
                            //@ts-ignore
                            error={props.state.positions[i].packaging ? false : true }
                            value={props.state.positions[i].packaging}
                            fullWidth
                            onChange={props.handlePosChange}
                            size='small'
                            disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                          >
                            <MenuItem value='bigbag' selected={props.state.positions[i].packaging === 'bigbag'}>Bigbag</MenuItem>
                            <MenuItem value='bale' selected={props.state.positions[i].packaging === 'bale'}>Ballen</MenuItem>
                            <MenuItem value='divers' selected={props.state.positions[i].packaging === 'divers'}>Divers</MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <div className="flex of-3 spacing-1 form" style={{marginTop: 15}}>
                        {props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view' ? (
                          <TextField
                            disabled
                            value={localNumber(props.state.positions[i].quantity) + ' ' + props.state.positions[i].unit}
                            size='small'
                            fullWidth
                            helperText='Menge'
                          />
                        ) : (
                          <div className='input-with-unit'>
                            <TextField
                              name={`quantity-${i}`}
                              type='number'
                              helperText={'Menge'}
                              //@ts-ignore
                              error={props.state.positions[i].quantity ? false : true }
                              value={props.state.positions[i].quantity || ''}
                              fullWidth
                              onChange={props.handlePosChange}
                              size='small'
                              disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                            />
                            <TextField
                              select
                              name={`unit-${i}`}
                              type='text'
                              //@ts-ignore
                              error={props.state.positions[i].unit ? false : true }
                              value={props.state.positions[i].unit}
                              fullWidth
                              onChange={props.handlePosChange}
                              size='small'
                              disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                            >
                              {unitOptions.map((option, j) => (
                                <MenuItem key={option.value + j} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                        {props.rights.includes('price::exit') && (
                          <>
                            {props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view' ? (
                              <TextField
                                disabled
                                value={(props.state.positions[i].priceUnit === 'kg' ? localPriceInTons(props.state.positions[i].price) : localNumber(props.state.positions[i].price)) + ' €/' + (props.state.positions[i].priceUnit === 'kg' ? 't' : props.state.positions[i].priceUnit)}
                                size='small'
                                fullWidth
                                helperText='Preis'
                              />
                            ) : (
                              <div className='input-with-unit'>
                                <TextField
                                  name={`price-${i}`}
                                  type='number'
                                  helperText={'Preis'}
                                  //@ts-ignore
                                  error={props.state.positions[i].price ? false : true }
                                  value={props.state.positions[i].price || ''}
                                  fullWidth
                                  onChange={props.handlePosChange}
                                  size='small'
                                  disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                                />
                                <TextField
                                  select
                                  name={`priceUnit-${i}`}
                                  type='text'
                                  //@ts-ignore
                                  error={props.state.positions[i].priceUnit ? false : true }
                                  value={props.state.positions[i].priceUnit}
                                  fullWidth
                                  onChange={props.handlePosChange}
                                  size='small'
                                  disabled={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view'}
                                >
                                  {unitOptions.map((option, j) => (
                                    <MenuItem key={option.value + j} value={option.value}>
                                      €/{option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            )}
                            <div>
                              <TextField
                                disabled
                                name={`total-${i}`}
                                type='text'
                                helperText={'Summe netto'}
                                //@ts-ignore
                                error={formatter.format(props.state.positions[i].total) ? false : true }
                                value={(formatter.format(props.state.positions[i].total) || '') + ' €'}
                                fullWidth
                                size='small'
                                InputProps={props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view' ? {} : {
                                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
            <div className="flex of-2 spacing-1">
              {(props.state.orderFormMode === 'create' || props.state.orderFormMode === 'edit') ? (
                <div onClick={props.addNewPos}>
                  <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><span className='plus-btn'>+</span>weitere Position hinzufügen</strong>
                </div>
              ) : <div>&nbsp;</div>}
              {props.rights.includes('price::exit') && (
                <div className='total-container text-right'>
                  <TextField
                    disabled
                    name={`total`}
                    type='text'
                    helperText={'Gesamt netto'}
                    error={props.state.total ? false : true }
                    value={props.state.total}
                    size='small'
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {(props.state.orderFormMode === 'create' || props.state.orderFormMode === 'edit') && (
            <div className='text-right' style={{marginTop: 30}}>
              {props.popMode === 'edit' && props.state.activeOrderIsDraft && <Button variant='outlined' color='error' sx={{marginRight: '15px'}} onClick={() => props.cancelExitOrder(props.state.activeOrderId ? props.state.activeOrderId : -1)}>Entwurf löschen</Button>}
              {props.popMode === 'edit' && !props.state.activeOrderIsDraft && <Button variant='outlined' color='error' sx={{marginRight: '15px'}} onClick={() => props.cancelExitOrder(props.state.activeOrderId ? props.state.activeOrderId : -1)}>Bestellung stornieren</Button>}
              {props.popMode === 'edit' && props.state.activeOrderIsDraft && <Button variant='contained' color='secondary' style={{marginRight: 10}} onClick={() => props.submitForm(true, true)}>Entwurf aktualisieren</Button>}
              {props.popMode === 'edit' && <Button variant='contained' onClick={() => props.submitForm(false, true)}>Auftrag aktualisieren</Button>}
              {props.popMode === 'create' && <Button variant='contained' color='secondary' style={{marginRight: 10}} onClick={() => props.submitForm(true, false)}>Als Entwurf erstellen</Button>}
              {props.popMode === 'create' && <Button variant='contained' onClick={() => props.submitForm(false, false)}>Auftrag erstellen</Button>}
            </div>
          )}

          {(props.state.orderFormMode === 'prepare' || props.state.orderFormMode === 'view') && (
            <div>
              <hr />
              <h2 style={{marginBottom: '-30px'}}>Lieferung</h2>
              {props.state.positions.length && props.state.positions.map((pos, i) => (
                <React.Fragment key={`pos-${i}`}>
                  <h3 style={{marginTop: 50}}><strong>Position {i + 1}</strong></h3>
                  {pos.trucks && pos.trucks.length >= 1 && pos.trucks.map((truck, j) => (
                    <div key={'truck' + i + j} style={{marginTop: 15}}>
                      <strong>LKW {j + 1}</strong> {props.state.orderFormMode === 'prepare' && <span onClick={() => props.deleteTruck(i, j)} style={{textDecoration: 'underline', cursor: 'pointer'}}>löschen</span>}
                      <div className='flex of-4 spacing-1' style={{marginTop: 15}}>
                        <TextField
                          name={`plate-${i}-${j}`}
                          type='text'
                          helperText={'Kennzeichen'}
                          error={props.state.positions[i].trucks[j].plate ? false : true }
                          value={props.state.positions[i].trucks[j].plate}
                          fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                          size='small'
                          disabled={props.state.orderFormMode === 'view'}
                        />
                        <TextField
                          name={`dispatchDate-${i}-${j}`}
                          type='date'
                          helperText={'Abholdatum'}
                          error={props.state.positions[i].trucks[j].dispatchDate ? false : true }
                          value={props.state.positions[i].trucks[j].dispatchDate instanceof Date ? toLocalISODate(props.state.positions[i].trucks[j].dispatchDate) : (props.state.positions[i].trucks[j].dispatchDate || '').toString().split('T')[0]}
                          fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                          size='small'
                          disabled={props.state.orderFormMode === 'view'}
                        />
                        {/* time slot */}
                        <TextField
                          name={`startTime-${i}-${j}`}
                          type='time'
                          helperText={'Start Time'}
                          error={props.state.positions[i].trucks[j].startTime ? false : true }
                          value={props.state.positions[i].trucks[j].startTime}
                          // fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                          size='small'
                          disabled={props.state.orderFormMode === 'view'}
                        />
                        
                         <TextField
                          name={`endTime-${i}-${j}`}
                          type='time'
                          helperText={'End Time'}
                          error={props.state.positions[i].trucks[j].endTime ? false : true }
                          value={props.state.positions[i].trucks[j].endTime}
                          fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                          size='small'
                          disabled={props.state.orderFormMode === 'view'}
                        />
                        <div></div>
                        <TextField
                          helperText={'ausgewählte Menge'}
                          value={localNumber(props.calcTruckTotal(i, j)) + ' kg'}
                          fullWidth
                          size='small'
                          disabled
                        />
                      </div>
                      <div className="flex of-2 spacing-1" style={{marginTop: 15}}>
                        <div className='twentyfive'>
                          <TextField
                            name={`storagePlace-${i}-${j}`}
                            select
                            helperText={props.state.orderFormMode === 'view' ? 'Lager' : 'Lager (du kannst dem Lagerplatz rechts entweder eine Menge ODER Produkte zuweisen)'}
                            value={props.state.positions[i].trucks[j].storagePlace || ''}
                            error={props.state.positions[i].trucks[j].storagePlace ? false : true }
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                            size='small'
                            disabled={props.state.orderFormMode === 'view'}
                          >
                            {props.state.stores.map((s, si) => (
                              <MenuItem key={'store'+s.id+si} value={s.id}>{s.attributes.name}</MenuItem>
                            ))}
                          </TextField>
                        </div>
                        {props.state.orderFormMode === 'prepare' && (
                          <div className="seventyfive">
                            <TextField
                              name={`storageTotal-${i}-${j}`}
                              type='number'
                              helperText={'zugewiesene Menge von Lagerplatz'}
                              value={props.state.positions[i].trucks[j].storageTotal}
                              error={props.state.positions[i].trucks[j].storageTotal ? false : true }
                              fullWidth
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleTruckChange(e, i, j)}
                              size='small'
                              InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>
                              }}
                              sx={{marginBottom: '15px'}}
                            />
                            <div className='goods-selector'>
                              <div className="inner">
                                {props.state.positions[i].trucks[j].goods.map((good: string, x) => (
                                  <span key={'abc' + good + x}>{good} <Close sx={{fontSize: '15px'}} className='error pointer' onClick={() => props.handleGood(i, j, good)} />, </span>
                                ))}
                              </div>
                            </div>
                            <FormHelperText sx={{marginLeft: '14px'}}>{props.state.orderFormMode === 'prepare' && 'ODER '}zugewiesene Produkte von Lagerplatz{props.state.orderFormMode === 'prepare' && <>- <span className='green pointer' onClick={() => showPop(i, j)}>hinzufügen</span></>}</FormHelperText>
                          </div>
                        )}
                        {props.state.orderFormMode === 'view' && (
                          <div className="seventyfive">
                            {props.state.positions[i].trucks[j].storageTotal ? (
                              <TextField
                                helperText={'zugewiesene Menge von Lagerplatz'}
                                error={props.state.positions[i].trucks[j].storageTotal ? false : true }
                                value={`${props.state.positions[i].trucks[j].storageTotal} kg`}
                                fullWidth
                                size='small'
                                disabled
                                sx={{marginBottom: '15px'}}
                              />
                            ) : ( 
                              <>
                                <div className='goods-selector'>
                                  <div className="inner">
                                    {props.state.positions[i].trucks[j].goods.map((good: string, x) => (
                                      <span key={'abc' + good + x}>{good}, </span>
                                    ))}
                                  </div>
                                </div>
                                <FormHelperText sx={{marginLeft: '14px'}}>zugewiesene Produkte von Lagerplatz</FormHelperText>
                              </>
                            )}
                          </div>
                        )}
                      </div>

                      <Modal
                        open={show && showPos === i && showTruck === j}
                        onClose={() => {setShow(false); setShowPos(-1); setShowTruck(-1); setStorage('')}}
                      >
                        <>
                          <span className='modal-close pointer' onClick={() => {setShow(false); setShowPos(-1); setShowTruck(-1); setStorage('')}}><Close /></span>
                          <Box sx={{...modalStyle, maxWidth: 800}}>
                            <span className='modal-close pointer' onClick={() => {setShow(false); setShowPos(-1); setShowTruck(-1); setStorage('')}}><Close /></span>
                            <h2>Lagerplätze</h2>

                            <div className="windowed">
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Kategorie</TableCell>
                                    <TableCell>Bezeichnung</TableCell>
                                    <TableCell>Farbe</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {props.state.goods.length >= 1 && props.state.goods.map(good => good.attributes.status === 'stored' && good.attributes.warehouse_spot.data && good.attributes.warehouse_spot.data.id.toString() === storage && (!good.attributes.exit_orders.data || good.attributes.exit_orders.data.length === 0 || good.attributes.exit_orders.data[0].id === props.state.activeOrderId) ? (
                                    <TableRow key={'good'+ good.id} sx={{cursor: 'pointer'}}>
                                      <TableCell>{good.attributes.customId}</TableCell>
                                      <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                                      <TableCell>{good.attributes.typeIntern}</TableCell>
                                      <TableCell>{localNumber(good.attributes.quantity)} {good.attributes.unit}</TableCell>
                                      <TableCell>{props.state.positions[i].trucks[j].goods.includes(good.attributes.customId) ? <Close className='error' onClick={() => props.handleGood(i, j, good.attributes.customId)} /> : <Add className='green' onClick={() => props.handleGood(i, j, good.attributes.customId)} />}</TableCell>
                                    </TableRow>
                                  ) : null)}
                                </TableBody>
                              </Table>
                            </div>
                          </Box>
                        </>
                      </Modal>
                    </div>
                  ))}
                  
                  {props.state.orderFormMode === 'prepare' && (
                    <div onClick={() => props.addTruckToPos(i)}>
                      <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><span className='plus-btn'>+</span>LKW hinzufügen</strong>
                    </div>
                  )}

                  <div style={{marginTop: 30}}>
                    <div><strong>Soll-Ist-Vergleich Position {i+1}</strong></div>
                    <div className="flex of-3 spacing-1" style={{marginTop: 15}}>
                      <TextField
                        disabled
                        helperText='Auftragsware'
                        value={localNumber(props.state.positions[i].quantity) + ' kg'}
                      />
                      <TextField
                        disabled
                        helperText='verladene Ware'
                        value={localNumber(calcTruckFulfilledAmount(props.state.positions[i])) + ' kg'}
                      />
                      <TextField
                        disabled
                        helperText='Differenz'
                        value={localNumber((props.state.positions[i].quantity - calcTruckFulfilledAmount(props.state.positions[i])) < 0 ? (props.state.positions[i].quantity - calcTruckFulfilledAmount(props.state.positions[i]))*-1 : (props.state.positions[i].quantity - calcTruckFulfilledAmount(props.state.positions[i]))) + ' kg'}
                      />
                    </div>
                  </div>

                  {props.state.orderFormMode === 'view' && (
                    <div style={{marginTop: 30}}>
                      <div><strong>Tatsächlich geliefert</strong></div>
                      {props.state.positions[i].trucks.length >= 1 && props.state.positions[i].trucks.map((truck, i) => truck.fulfilled ? (
                        <div style={{marginTop: 15}} key={`fulfilledTruck-${i}`}>
                          <strong>LKW {i + 1}</strong>
                          <Table>
                            <TableHead>
                              <TableCell>ID</TableCell>
                              <TableCell>Bezeichnung</TableCell>
                              <TableCell>Menge</TableCell>
                            </TableHead>
                            <TableBody>
                              {truck.fulfillData.length >= 1 && truck.fulfillData.map((data, j) => (
                                <TableRow key={`fulfilledTruck-${i}-${j}`}>
                                  <TableCell onClick={() => props.showProductDetail(getGoodData(data.id).id)}>{data.id}</TableCell>
                                  <TableCell>{getGoodData(data.id).type}</TableCell>
                                  <TableCell>{data.amount} kg</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      ) : (
                        <div style={{marginTop: 15}} key={`fulfilledTruck-${i}`}>
                          <strong>LKW {i + 1}:</strong> noch nicht abgewickelt
                        </div>
                      ))}
                    </div>
                  )}
                  <hr />
                </React.Fragment>
              ))}

              {props.state.orderFormMode !== 'view' && (
                <div style={{marginTop: 30}} className="text-right">
                  {props.state.orderFormMode === 'prepare' && !props.state.activeOrderIsDraft && <Button variant='outlined' color='error' sx={{marginRight: '15px'}} onClick={() => props.cancelExitOrder(props.state.activeOrderId ? props.state.activeOrderId : -1)}>Bestellung stornieren</Button>}
                  <Button variant='contained' onClick={() => props.sendPrepareData()}>Auftrag aktualisieren</Button>
                </div>
              )}
            </div>
          )}
        </Box>
      </>
    </Modal>




    <Modal
          open={props.state.showDetailPop && props.state.activeGood !== undefined}
          onClose={props.onCloseDetailPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="detail-pop"
        >
          <>
            <span className='modal-close pointer' onClick={props.onCloseDetailPop}><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer' onClick={props.onCloseDetailPop}><Close /></span>
              <div className="header-bar">
                <h2>Detailansicht</h2>
                {props.state.activeGood && props.state.activeGood?.attributes.label && props.state.activeGood?.attributes.label.data ? (
                  <div><a href={props.state.activeGood.attributes.label.data.attributes.url} target='_blank' rel='noreferrer' style={{ color: 'white' }}><Button variant='contained'><QrCode2 /></Button></a></div>
                ) : null}
              </div>
              <h3>Grunddaten</h3>
              <div className="flex of-4 spacing-1">
                <TextField
                  helperText='ID'
                  value={props.state.activeGood?.attributes.customId || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText='Kategorie'
                  value={props.state.activeGood?.attributes.category ? convertGoodsCategory(props.state.activeGood?.attributes.category) : 'nicht gefunden'}
                  disabled
                />
                <div style={{ position: 'relative' }}>
                  <TextField
                    helperText='Bezeichnung'
                    value={props.state.activeGood?.attributes.typeIntern || 'nicht gefunden'}
                    name='typeIntern'
                    disabled={props.state.enableUpdate !== 'typeIntern'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                    fullWidth
                  />
                  <span className='update-field' 
                  onClick={() => props.handleUpdate('typeIntern')}
                  >
                    {props.state.enableUpdate === 'typeIntern' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {props.state.enableUpdate === 'color' ? (
                    <TextField
                      helperText='Farbe'
                      value={props.state.activeGood?.attributes.color || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                      fullWidth
                      select
                      name='color'
                    >
                      {rpetColors.map(value => (
                        <MenuItem key={`color-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Farbe'
                      value={convertOrderAttributes(props.state.activeGood?.attributes.color || 'n/a')}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => props.handleUpdate('color')}>
                    {props.state.enableUpdate === 'color' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {props.state.enableUpdate === 'foodSafe' ? (
                    <TextField
                      helperText='LME'
                      value={props.state.activeGood?.attributes.foodSafe || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='foodSafe'
                    >
                      {foodSafeOptions.map(value => (
                        <MenuItem key={`foodSafe-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='LME'
                      value={convertOrderAttributes(props.state.activeGood?.attributes.foodSafe || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => props.handleUpdate('foodSafe')}>
                    {props.state.enableUpdate === 'foodSafe' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {props.state.enableUpdate === 'material' ? (
                    <TextField
                      helperText='Material'
                      value={props.state.activeGood?.attributes.material || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='material'
                    >
                      {materials.map(value => (
                        <MenuItem key={`material-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Material'
                      value={convertOrderAttributes(props.state.activeGood?.attributes.material || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => props.handleUpdate('material')}>
                    {props.state.enableUpdate === 'material' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='integriert von'
                  value={props.state.activeGood?.attributes.creator.data?.attributes.name || '???'}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText='Status'
                  value={translate(props.state.activeGood?.attributes.status || '???')}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
              </div>
              <h3>Lager</h3>
              <div className="flex of-4 spacing-1">
                <div style={{ position: 'relative' }}>
                  <TextField
                    type={props.state.enableUpdate === 'quantity' ? 'number' : 'text'}
                    helperText='Menge'
                    value={props.state.enableUpdate === 'quantity' ? Number(props.state.activeGood?.attributes.quantity || 0) : (localNumber(props.state.activeGood?.attributes.quantity || '') || 'nicht gefunden')}
                    fullWidth
                    name='quantity'
                    disabled={props.state.enableUpdate !== 'quantity'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{props.state.activeGood?.attributes.unit || ''}</InputAdornment>
                    }}
                  />
                  <span className='update-field' onClick={() => props.handleUpdate('quantity')}>
                    {props.state.enableUpdate === 'quantity' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='Wert'
                  value={(props.state.activeGood?.attributes.baseValue ? localCurrency((props.state.activeGood?.attributes.baseValue) * props.state.activeGood?.attributes.quantity) : 0)}
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
                <div style={{ position: 'relative' }}>
                  {props.state.enableSpotChange ? (
                    <TextField
                      helperText='Lagerplatz'
                      value={props.state.newSpot || ''}
                      // onChange={e => props.setState({ newSpot: e.target.value })}
                      fullWidth
                      select
                    >
                      {props.state.spots && props.state.spots.length >= 1 && props.state.spots.map(spot => (
                        <MenuItem key={`spot-${spot.id}`} value={spot.id}>{spot.attributes.name} - {localNumber(spot.attributes.capacity)} kg frei</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Lagerplatz'
                      value={props.state.activeGood?.attributes.warehouse_spot.data ? props.state.activeGood?.attributes.warehouse_spot.data.attributes.name : 'nicht gefunden'}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={props.handleSpotChange}>
                    {props.state.enableSpotChange ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='erstellt am'
                  value={props.state.activeGood?.attributes.createdAt ? convertDateString(props.state.activeGood?.attributes.createdAt) : 'nicht gefunden'}
                  disabled
                />
              </div>

              <h3>Dokumente</h3>
              <div className="flex of-4 spacing-1 documents">
                <Link to={props.state.activeGood?.attributes.fromBatch && props.state.activeGood?.attributes.fromBatch.data ? `/produktion/chargen?id=${props.state.activeGood.attributes.fromBatch.data.id}` : '#'}>
                  <TextField
                    helperText='ID Charge'
                    value={props.state.activeGood?.attributes.fromBatch && props.state.activeGood?.attributes.fromBatch.data ? props.state.activeGood?.attributes.fromBatch.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={props.state.activeGood?.attributes.fromBatch && props.state.activeGood?.attributes.fromBatch.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={props.state.activeGood?.attributes.order && props.state.activeGood?.attributes.order.data ? `/wareneingang?id=${props.state.activeGood.attributes.order.data.id}` : '#'}>
                  <TextField
                    helperText='ID Bestellung'
                    value={props.state.activeGood?.attributes.order && props.state.activeGood?.attributes.order.data ? props.state.activeGood?.attributes.order.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={props.state.activeGood?.attributes.order && props.state.activeGood?.attributes.order.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={props.state.activeGood?.attributes.exit_orders && props.state.activeGood?.attributes.exit_orders.data && props.state.activeGood?.attributes.exit_orders.data[0] && props.state.activeGood?.attributes.exit_orders.data[0].attributes ? `/warenausgang?id=${props.state.activeGood?.attributes.exit_orders.data[0].id}` : '#'}>
                  <TextField
                    helperText='ID Auftrag'
                    value={props.state.activeGood?.attributes.exit_orders && props.state.activeGood?.attributes.exit_orders.data && props.state.activeGood?.attributes.exit_orders.data[0] && props.state.activeGood?.attributes.exit_orders.data[0].attributes && props.state.activeGood?.attributes.exit_orders.data[0].attributes.customId ? props.state.activeGood?.attributes.exit_orders.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={props.state.activeGood?.attributes.exit_orders && props.state.activeGood?.attributes.exit_orders.data && props.state.activeGood?.attributes.exit_orders.data[0] && props.state.activeGood?.attributes.exit_orders.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                  <TextField
                    helperText='Laborbericht'
                    value={props.state.activeGood?.attributes.laboratory_reports && props.state.activeGood?.attributes.laboratory_reports.data && props.state.activeGood?.attributes.laboratory_reports.data[0] && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId ? props.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={props.state.activeGood?.attributes.laboratory_reports && props.state.activeGood?.attributes.laboratory_reports.data && props.state.activeGood?.attributes.laboratory_reports.data[0] && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
              </div>
              {
                props.state.activeGood?.attributes.laboratory_reports && props.state.activeGood?.attributes.laboratory_reports.data && props.state.activeGood?.attributes.laboratory_reports.data[0] && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId&&
                <div className='flex of-4 spacing-1 documents'>
                  <Link to={props.state.activeGood?.attributes.laboratory_reports && props.state.activeGood?.attributes.laboratory_reports.data && props.state.activeGood?.attributes.laboratory_reports.data[0] && props.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? `/labor?id=${props.state.activeGood?.attributes.laboratory_reports.data[0].id}` : '#'}>
                    <Button  sx={{ marginTop: '15px' }}className="pt-5" variant='contained'>Go {props.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId}</Button>
                  </Link>
                </div>
              }

              <h3>Status</h3>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Aktueller Status</TableCell>
                      <TableCell align='right'>{translate(props.state.activeGood?.attributes.status || 'nicht bekannt')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Geplante Abholung (Warenausgang)</TableCell>
                      <TableCell align='right'>{props.state.activeGood?.id ? props.checkExitDate(props.state.activeGood?.attributes.customId) : '???'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <h3>Status</h3> */}
              {/* //TODO */}
            </Box>
          </>
        </Modal>
   
   </>
  



  );
}

export default WarehouseExitForm;
